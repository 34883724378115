<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>RUBRIK </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateRubrik">
              <CRow>
                <CCol sm="6">
                <CInput
                  v-model="name"
                  label="Nama Rubrik"
                  placeholder="Input Nama Rubrik"
                />
                </CCol>
                <CCol sm="6">
                  <CInput
                type="number"
                  v-model="position"
                  label="Urutan Rubrik"
                  placeholder="Input Urutan Rubrik"
                />
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                  <label> Status</label>
                  <select v-model="status" placeholder='Pilih Status' class="form-control">
                    <option value="" disabled selected>Pilih Status</option>
                    <option value="1">Aktif</option>
                    <option value="0">Non-Aktif</option>
                  </select>
                  </div>
                </CCol>      
              </CRow>       
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/rubrik">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Rubrik gagal diinput.
    </CModal>
   
  </div> 
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      name : "",
      position : "",
      status: "",
      // fproduct_price : "",
      // qty : "",
      // barcode : "",
      // alert : "",
      // unit : "",
      // product_des : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showRubrik();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateRubrik: function(event){
      // Simple POST request with a JSON body using axios
      const rubrik = { 
                          name:this.name,
                          position: this.position, 
                          status: this.status, 
                          id: this.$route.params.id_rubrik,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"rubrik/update", rubrik)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/rubrik');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showRubrik: function(event){

      axios.get(process.env.VUE_APP_BASE_URL+"rubrik/"+this.$route.params.id_rubrik)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            // if(response.status==200){
              // this.$router.push('/User');
              this.name=response.data.name;
              this.position=response.data.position;
              this.status=response.data.status;
              // this.fproduct_price=response.data.fproduct_price;
              // this.qty=response.data.qty;
              // this.barcode=response.data.barcode;
              // this.alert=response.data.alert;
              // this.unit=response.data.unit;
              // this.product_des=response.data.product_des;

              // alert(response.data);
            // }
            // else{
            //   this.$root.$emit("bv::show::modal", "modalError");
            // }
            
        })
    }
  }
}
</script>